import styled from 'styled-components';

const StyledImage = styled.div`
  .gatsby-image-wrapper {
    height: 100vh;
    width: 100vw;
    filter: grayscale(1);
    &:after {
      background-image: linear-gradient(to bottom, rgba(6, 42, 48, 0.2) 0, rgba(6, 42, 48, 0.5) 100%);
      content: '';
      width: 100vw;
      height: 100vh;
      top: 0;
      bottom: 0;
      position: absolute;
    }
  }
  .hidden-signature {
    display: none;
  }
`;

export default StyledImage;

import styled from 'styled-components';

const StyledFooterCredits = styled.div`
  background: ${props => (props.transparent ? 'transparent' : '#0f262e')};
  color: #fff;
  font-size: 0.75rem;
  padding: 1rem;
  text-align: center;
  width: 100%;
  position: relative;
  height: 100px;
  div {
    &:first-child {
      color: #00ff00;
    }
  }
  a {
    position: absolute;
    left: 2rem;
    top: 2rem;
    color: #fff;
    &:hover {
      color: #00ff00;
    }
  }
  img {
    position: absolute;
    right: 2rem;
    top: 0;
  }
`;

export default StyledFooterCredits;

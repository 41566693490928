import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { getLocalizedPath } from '../core/i18n';

const LanguageSwitch = ({ locale, location }) => {
  const language = locale === 'nl' ? 'English' : 'Nederlands';
  const switchToLocale = locale === 'nl' ? 'en' : 'nl';
  return <Link to={getLocalizedPath(location.pathname, switchToLocale)}>{language}</Link>;
};

LanguageSwitch.propTypes = {
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default LanguageSwitch;

import styled, { keyframes } from 'styled-components';

const shiftX = keyframes`
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(3px);
    }
`;

const StyledArrowRight = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
  color: white;
  fill: white;
  animation: ${shiftX} 0.5s ease-out infinite alternate;
`;

export default StyledArrowRight;

import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledDialog = styled.div`
  display: ${props => (props.isToggled ? 'block' : 'none')};
  .content {
    animation: ${fade} 0.3s forwards;
    background: white;
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-top: 16px;
      .lhs {
        font-size: 10px;
        flex: 1;
      }
      .rhs {
        margin-left: 32px;
        a {
          font-size: 14px;
          width: 85.2px;
          > span {
            display: inline;
          }
          button {
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: 600px) and (min-height: 450px) {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 20;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.5);
    .content {
      width: 620px;
      min-height: auto;
      height: ${props => (props.height ? `${props.height}px` : '510px')};
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 20px;
      padding: 40px;
      .footer {
        width: calc(100% - 40px);
        margin-top: 0;
        position: absolute;
        bottom: -48px;
        left: 20px;
        .lhs {
          color: #fff;
        }
        .rhs {
          a {
            > span {
              display: none;
            }
            button {
              display: block;
            }
          }
        }
      }
    }
  }
`;

export default StyledDialog;

import styled from 'styled-components';

const StyledContentWrapper = styled.div`
  flex: 1 0 auto;
  align-items: baseline;
  justify-content: center;

  display: flex;

  @media (min-width: 480px) {
    align-items: center;
  }
`;

export default StyledContentWrapper;

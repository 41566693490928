import styled from 'styled-components';

const StyledHeadline = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #333c4e;
  padding: 1rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #3e495e;
  }
  p {
    color: #fff;
    margin: 0;
  }
  strong {
    color: #00ff00;
  }
`;

export default StyledHeadline;

import styled from 'styled-components';
import { transformItem, fadeItem } from '../common/animation';

const StyledContent = styled.div`
  max-width: 1000px;
  padding: 0 2rem;
  width: 100%;
  z-index: 0;
  opacity: 0;
  animation: ${transformItem} 1.5s ease-out, ${fadeItem} 1s 0.5s 1 ease-out forwards;

  button {
    line-height: 2rem;
  }

  @media (min-width: 1440px) {
    max-width: 840px;
    .e-btn span {
      display: inline-block;
    }
  }
  @media (min-width: 1440px) {
    max-width: 1000px;
  }

  div {
    &:first-child {
      p {
        position: relative;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 2rem;
        transition: all 0.5s ease-out;
        margin-bottom: calc(2 * 0.5rem);

        &:after {
          background: #00ff00;
          bottom: -0.5rem;
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          width: 2rem;
        }

        strong {
          font-weight: 400;
          display: block;
        }

        @media (min-width: 768px) {
          font-size: 2.4rem;
          line-height: 3.2rem;
          margin-bottom: calc(5 * 0.5rem);

          &:after {
            bottom: -1.2rem;
          }
          strong {
            font-size: 3rem;
          }
        }
        @media (min-width: 1024px) {
          font-size: 2.4rem;
          line-height: 3.2rem;
          margin-bottom: calc(4 * 0.5rem);

          &:after {
            bottom: -1.2rem;
          }
          strong {
            font-size: 3rem;
          }
        }
        @media (min-width: 1024px) {
          font-size: 3.4rem;
          line-height: 4.2rem;
          margin-bottom: calc(8 * 0.5rem);

          &:after {
            bottom: -2.2rem;
          }
          strong {
            font-size: 4rem;
          }
        }
      }
    }

    &:nth-child(2) {
      p {
        color: #ffffff;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6rem;
        transition: all 0.5s ease-out;

        @media (min-width: 1024px) {
          font-size: 1.2rem;
          line-height: 2.1rem;
        }
        @media (min-width: 1440px) {
          font-size: 1.5rem;
          line-height: 2.6rem;
        }
      }
    }
  }
`;

export default StyledContent;

import styled, { keyframes } from 'styled-components';
import spriteLarge from '../../assets/images/e-wink-sprite-lg.png';
import sprite from '../../assets/images/e-wink-sprite.png';

const animateX = keyframes`
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -600px;
  }
`;

const animateY = keyframes`
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -1200px;
  }
`;

const animateXLarge = keyframes`
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -800px;
  }
`;

const animateYLarge = keyframes`
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -1600px;
  }
`;

const StyledEWink = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 75px;
  height: 75px;
  background-image: url('${sprite}');
  cursor: pointer;
  z-index: 1;
  animation: ${animateX} .3s steps(8) infinite, ${animateY} 4.8s steps(16) infinite;
  @media (min-width: 600px) and (min-height: 450px) {
    background-image: url('${spriteLarge}');
    width: 100px;
    height: 100px;
    animation: ${animateXLarge} .3s steps(8) infinite, ${animateYLarge} 4.8s steps(16) infinite;
  }
`;

export default StyledEWink;

import React from 'react';
import PropTypes from 'prop-types';
import { getLocalizedPath } from '../core/i18n';

import IconArrowRight from '../assets/images/icon-arrow-right.svg';
import StyledHeadline from '../style/headline/styledHeadline';
import StyledArrowRight from '../style/headline/styledArrowRight';

const Headline = ({ title, slug, locale }) => (
  <StyledHeadline href={getLocalizedPath(slug, locale)}>
    <div dangerouslySetInnerHTML={{ __html: title }} />
    <StyledArrowRight>
      <IconArrowRight />
    </StyledArrowRight>
  </StyledHeadline>
);

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Headline;

import styled from 'styled-components';

const StyledEContent = styled.div`
  .header {
    margin-bottom: 32px;
    display: flex;

    img {
      width: 100px;
      height: 100px;
    }
  }
  .title-container {
    font-weight: 700;
    font-size: 30px;
    color: #00ff00;
    margin-bottom: 32px;
  }
  .description-container {
    margin-bottom: 16px;
    span {
      margin-bottom: 16px;
      &:nth-child(1) {
        color: #484848;
        font-size: 16px;
        display: block;
      }
      &:nth-child(2) {
        color: #2c3633;
        font-size: 18px;
      }
    }
  }
  .code-box {
    padding: 10px;
    background-color: #2c3633;
    width: 100%;
    border-radius: 7px;
    font-family: 'Andale Mono';
    font-size: 13px;
    margin-bottom: 32px;
    .row-number {
      color: #495c56;
      margin-right: 8px;
    }
    .dark-green {
      color: #00ff00;
    }
    .light-green {
      color: #c0f57f;
    }
    span {
      &:nth-child(3),
      &:nth-child(9) {
        color: #fff;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  @media (min-width: 600px) and (min-height: 450px) {
    .header {
      position: relative;
      img {
        width: 200px;
        height: 200px;
        position: absolute;
        top: -140px;
      }
      & > div {
        position: absolute;
        top: -88px;
        & > div {
          opacity: 1;
          &::before,
          &::after {
            background: #fff;
          }
        }
      }
    }
    .title-container {
      margin-top: 92px;
    }
  }
`;

export default StyledEContent;

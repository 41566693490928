import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Link from './link';

import StyledDialog from '../style/dialog/styledDialog';
import StyledEContent from '../style/dialog/styledEContent';
import StyledButton from '../style/common/styledButton';
import StyledCloseButton from '../style/menu/styledCloseButton';
import EProfilePic from '../assets/images/e-profile-pic.png';

const EDialog = ({ jobsUrl, toggle, toggleState }) => (
  <StyledDialog isToggled={toggleState}>
    <div className="content">
      <StyledEContent>
        <div className="header">
          <img src={EProfilePic} />
          <StyledCloseButton noPadding onClick={toggle}>
            <div />
          </StyledCloseButton>
        </div>
        <div className="title-container">
          <FormattedMessage id="E.title" />
        </div>
        <div className="description-container">
          <FormattedMessage id="E.description" />
          <FormattedMessage id="E.description.2" />
        </div>
        <div className="code-box">
          <span className="row-number">1</span>
          <span className="dark-green">&lt;div&gt;</span>
          <FormattedMessage id="E.code.1" />
          <span className="dark-green">&lt;/div&gt;</span>
          <br />
          <span className="row-number">2</span>
          <span className="dark-green">console</span>
          <span className="light-green">.log(</span>
          <FormattedMessage id="E.code.2" />
          <span className="light-green">);</span>
        </div>
        <div className="button-container">
          <a href="https://challenge.euri.com/">
            <StyledButton noMargin height="3.5rem">
              BEAT E.
            </StyledButton>
          </a>
        </div>
      </StyledEContent>
      <div className="footer-container">
        <div className="footer">
          <div className="lhs">
            <FormattedMessage id="E.footer.lhs.1" />
            <br />
            <FormattedMessage id="E.footer.lhs.2" />
          </div>
          <div className="rhs">
            <Link to={jobsUrl}>
              <FormattedMessage id="E.footer.rhs.action" />
              <StyledButton noMargin smallPadding color="#fff">
                <FormattedMessage id="E.footer.rhs.action" />
              </StyledButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </StyledDialog>
);

EDialog.propTypes = {
  jobsUrl: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleState: PropTypes.bool.isRequired,
};

export default EDialog;
